import Navi from './component/navi';
import Banner from './component/banner';
import Slider from './component/slider';
import Body from './component/body';
import About from './component/about';
import Foot from './component/foot';
function App() {
  return (
    <div className="App">
      <Navi />
      <Banner />
      {/* <Slider /> */}
      {/* <Body />
      <About /> */}
      <Foot />
    </div>
  );
}

export default App;
