import {React,Component} from 'react';
import ReactDOM from 'react-dom';

export default class About extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    render(){
    return(
		<div className="block about">
			<h2 className="title line">关于我们</h2>
			<p className="sub-title">ABOUT US</p>
			<div className="desc">
				眼光更加长远。毕竟随着公司做大，也意味着越来越多的员工不理解公司的价值观。在某些看着不重要的岗位上，比如客<br/> 服，如果他对自己的定位只是接电话的，那就会尽可能减少自己的工作，不会主动反应问题。
				<br/> 相反，如果客服的动机是帮助别人，那这个岗位其实是最好的用户沟通平台，很多隐藏问题都能得到及早解决，
				<br/> 当整个企业的员工都能从长远角度思考问题，这家公司的产品一定更有品质；
			</div>
			<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">查看更多</a>
		</div>
    );
    }
}
