import {React,Component} from 'react';
import ReactDOM from 'react-dom';

export default class Slider extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    render(){
    return(
		<div className="slider swiper-container swiper-container-horizontal">
			<ul className="swiper-wrapper" style={{transform: "translate3d(-2031px, 0px, 0px)", transitionDuration: "0ms"}}>
                <li className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="2" style={{width: "2031px"}}>
					<img src="./蓝色智能科技企业网站首页_files/banner1.png"/>
					<div className="slider-txt">
						<div className="title">全自动化<br/>高科技生产技术</div>
						<div className="desc">对于研究者和决策者来说，从统计意义上弄清楚三者的概念<br/> 与关系，对于正确理解和使用相关数据是十分必要的。
						</div>
						<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多 &gt;&gt;</a>
					</div>
				</li>
				<li className="swiper-slide swiper-slide-active" data-swiper-slide-index="0" style={{width: "2031px"}}>
					<img src="./蓝色智能科技企业网站首页_files/banner1.png"/>
					<div className="slider-txt">
						<div className="title">全自动化<br/>高科技生产技术</div>
						<div className="desc">对于研究者和决策者来说，从统计意义上弄清楚三者的概念<br/> 与关系，对于正确理解和使用相关数据是十分必要的。
						</div>
						<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多 &gt;&gt;</a>
					</div>
				</li>
				<li className="swiper-slide swiper-slide-next" data-swiper-slide-index="1" style={{width: "2031px"}}>
					<img src="./蓝色智能科技企业网站首页_files/banner1.png"/>
					<div className="slider-txt">
						<div className="title">全自动化<br/>高科技生产技术</div>
						<div className="desc">对于研究者和决策者来说，从统计意义上弄清楚三者的概念<br/> 与关系，对于正确理解和使用相关数据是十分必要的。
						</div>
						<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多 &gt;&gt;</a>
					</div>
				</li>
				<li className="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="2" style={{width: "2031px"}}>
					<img src="./蓝色智能科技企业网站首页_files/banner1.png"/>
					<div className="slider-txt">
						<div className="title">全自动化<br/>高科技生产技术</div>
						<div className="desc">对于研究者和决策者来说，从统计意义上弄清楚三者的概念<br/> 与关系，对于正确理解和使用相关数据是十分必要的。
						</div>
						<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多 &gt;&gt;</a>
					</div>
				</li>
			    <li className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="0" style={{width: "2031px"}}>
					<img src="./蓝色智能科技企业网站首页_files/banner1.png"/>
					<div className="slider-txt">
						<div className="title">全自动化<br/>高科技生产技术</div>
						<div className="desc">对于研究者和决策者来说，从统计意义上弄清楚三者的概念<br/> 与关系，对于正确理解和使用相关数据是十分必要的。
						</div>
						<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多 &gt;&gt;</a>
					</div>
				</li>
            </ul>
			<div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            </div>
		</div>
    );
    }
}
