import {React,Component, Fragment} from 'react';
import { Image, Button, Space } from 'antd';
const contentStyle = {
	height: '160px',
	color: '#fff',
	lineHeight: '0px',
	width: "100%",
	//height:"160px",
	textAlign: 'center',
	background: '#364d79',
	//position: "absolute"
  };
const slidertxt = {
	position: "absolute",
	top: "30%",
	left: "17%",
	zIndex: 5,
	color: "#FFFFFF",
}
export default class Banner extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }
	displayTex =()=>{
		let iamgeList = [];
		for(let i = 1; i<14; i++){
			let path = "./images/" + i + ".png"
			iamgeList.push(<img
				preview={false}
				src={path}
				key = {i}
				style={{width:"100%"}}
			/>);
			console.log(path);
		}
		return (
			<div>
			{iamgeList}
			</div>
		)
	}
    render(){
    return(
		<Fragment>
			<this.displayTex />
		</Fragment>
    );
    }
}
