import {React,Component} from 'react';
import ReactDOM from 'react-dom';

export default class Body extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    render(){
    return(
		<div>
            <div className="block technology">
			<h2 className="title line">我们的技术</h2>
			<p className="sub-title">OUR TECHNOLOGY</p>
			<div className="desc">
				人工智慧从字面上看就是人造的智慧，但是实际上涵盖了不少部分，大家最关心的实际上是人工自主意识，<br/> 因为网络和电脑已经完成了知识的检索和存储，几大搜索引擎也完成了关键字
				<br/> -关联解释的功能和海量数据积累，而且大多数机器人厂商已经完成了反应机，自适应等等高级功能，但是还是没有能出现通过图灵测试的人工自主意识，
				<br/> 当然这也包含了人类对自身意识的研究并没有上升到更透彻的层面。
			</div>
			<div className="list">
				<ul>
					<li className="one"></li>
					<li className="two"></li>
					<li className="three"></li>
				</ul>
			</div>
		</div>
		<div className="block pattern">
			<div className="pattern-l">
				<ul>
					<li className="one active">
						<h5>商店</h5>
						<p>无人模式无人模式</p>
					</li>
					<li className="two">
						<h5>商店</h5>
						<p>无人模式无人模式</p>
					</li>
					<li className="three">
						<h5>商店</h5>
						<p>无人模式无人模式</p>
					</li>
				</ul>
			</div>
			<div className="pattern-r active">
				<div className="title">上市公司旗下品牌，更有保障</div>
				<div className="desc">事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答，帮助学员选择好机构事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答帮助学员选择好机构事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答，帮助学员选择好机构。</div>
				<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多&gt;&gt;</a>
			</div>
			<div className="pattern-r">
				<div className="title">上市公司旗下品牌，更有实力</div>
				<div className="desc">上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力上市公司旗下品牌，更有实力</div>
				<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多&gt;&gt;</a>
			</div>
			<div className="pattern-r">
				<div className="title">上市公司旗下品牌，更有技术</div>
				<div className="desc">事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答，帮助学员选择好机构事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答帮助学员选择好机构事实证明，了解清楚这6个问题，学员就能选到合适的培训机构；围绕这些问题，千库全面解答，帮助学员选择好机构。</div>
				<a href="https://www.17sucai.com/preview/775073/2021-04-12/zn/index.html#" className="btn">了解更多&gt;&gt;</a>
			</div>
		</div>
		<div className="block innovate">
			<h2 className="title line">创新科技</h2>
			<p className="sub-title">INNOVATIVE TECHNOLOGY</p>
			<div className="desc">
				管理者在面试时必问的问题就是，“你为什么选择来我们这里？”但是坦白讲，<br/> 很多人问这个问题不过是模式化的提问，得到的信息很浅显。
				<br/> 有的管理者不解，说我招人只需要他的能力符合就可以了，我是让他来干活的，至于他为什么要干，我并不关心。
			</div>
			<div className="list">
				<ul>
					<li className="one">
						<h4>AI智能</h4>
						<p>业务拓展到新领域，以前跟随打天下值得信任的人都不熟悉这个领域，要寻找这个领域的人才，对这个领域的哪些人真正胜任心...</p>
					</li>
					<li className="two">
						<h4>机器人</h4>
						<p>业务拓展到新领域，以前跟随打天下值得信任的人都不熟悉这个领域，要寻找这个领域的人才，对这个领域的哪些人真正胜任心...</p>
					</li>
					<li className="three">
						<h4>科技</h4>
						<p>业务拓展到新领域，以前跟随打天下值得信任的人都不熟悉这个领域，要寻找这个领域的人才，对这个领域的哪些人真正胜任心...</p>
					</li>
					<li className="four">
						<h4>软件</h4>
						<p>业务拓展到新领域，以前跟随打天下值得信任的人都不熟悉这个领域，要寻找这个领域的人才，对这个领域的哪些人真正胜任心...</p>
					</li>
				</ul>
			</div>
		</div>
        </div>
    );
    }
}
