import {React,Component} from 'react';
import ReactDOM from 'react-dom';

export default class Foot extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    render(){
    return(
		<div>
			<div className="copyright">
				<span>成都示踪科技有限公司 Copyright © 2014-2021 </span>
				<a href="https://beian.miit.gov.cn/" className="beian" target="_blank">蜀ICP备20000920号-1</a>
				<span>用时：0.0012</span>
				<a href="">关于我们</a>
				<a href="">版权声明</a>
			</div>
        </div>
    );
    }
}
