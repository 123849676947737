import {React,Component} from 'react';
import { Menu,Button } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const header = {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "95px",
    background: "rgba(255,255,255,.2)",
    padding: "0 12%",
    zIndex: 99,
    borderBottomWidth: "0px"

}
export default class Navi extends Component {
  state = {
    current: 'mail',
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <Menu onClick={this.handleClick}  mode="horizontal" style={header}>
        {/* <img src="./textures/logo.png" style={{maxWidth: "5%",maxHeight: "5%"}}/> */}
        <div style={{position:"absolute",right:"1px",top:"25px",zIndex:99}}>
        <Button type="text"  > 产品</Button>
        <Button type="text"  > 合作伙伴</Button>
        <Button type="text"  > 关于我们</Button>
        </div>
      </Menu>
    );
  }
}
